import React, {Fragment} from "react"
import { useStaticQuery, graphql } from "gatsby"

import Cassette from "../components/cassette"
import Headline from "../components/headline"
import RecentArticles from  "../components/recent-articles"

function RlatedArticles({description}){
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            excerpt(truncate: true)
            fields {
              slug
            }
            html
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
              hero
            }
          }
        }
      }
    }
    `)

  const posts = data.allMarkdownRemark.edges;
  const str = description ?  description : "";

  if(str === "" ){
    return (
      <RecentArticles />
    );
  } else {
    const tag = str.split(',')[0];
    const reg = new RegExp(tag);
    const title = `${tag} カテゴリーの最新記事`;
    const cassets = posts.filter(n => { return n.node.frontmatter.description != null &&  n.node.frontmatter.description.match(reg)}).map(({ node }) => {
      return(<Cassette node={node} link={node.fields.slug.substring(1)} key={node.fields.slug} />);
    });

    return (
      <Fragment>
        <Headline str={title}/>
          {cassets.slice(0, 4)}
      </Fragment>
    );
  }
}

export default RlatedArticles
