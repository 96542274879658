import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AdArticleUnderRect from "../components/ad-article-under-rect"
import AdArticleInnerMini from "../components/ad-article-inner-mini"
import RelatedArticles from "../components/related-articles"
import Tags from "../components/tags"
import { rhythm } from "../utils/typography"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle} description={siteDescription}>
      <SEO
        title={post.frontmatter.title}
        img={post.frontmatter.hero}
        description={post.frontmatter.description || post.excerpt}
        slug={post.fields.slug}
      />
      <article>
        <header>
          <section
            style={{
              marginBottom: 5,
              display: "inline-block",
              paddingLeft: 4,
              paddingRight: 4,
              backgroundColor: "#000",
              color: "#fff",
              fontSize: 10,
            }}
          >
            {post.frontmatter.date}
          </section>
          <h2
            style={{
              marginBottom: 10,
              backgroundColor: "#eee",
              borderLeft: "10px solid #111",
              paddingLeft: '10px',
              paddingBottom: '10px',
              paddingTop: '10px',
            }}
          >
            {post.frontmatter.title}
          </h2>
        </header>
        <Tags tags={ post.frontmatter.description }/>
        <AdArticleInnerMini slot={"/22116655052/h-article-inner-1"} id={"div-gpt-ad-1601181081263-0"} />
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <AdArticleUnderRect slot={"/22116655052/h-article-under-1"} id={"div-gpt-ad-1601184917895-0"} />
        <footer>
          <RelatedArticles description={ post.frontmatter.description } />
          <Bio />
        </footer>
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      fields {
        slug
      }
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
